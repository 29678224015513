import { Type } from '@camino-solutions/share/proto/common/forms';
import { isNil, isNumeric, isString } from '@camino-solutions/utils/typeguard';

export type UploadedFile = Type.FileMetadata.AsObject;

export function isUploadedFile(obj: unknown): obj is UploadedFile {
  return (
    obj !== null &&
    typeof obj === 'object' &&
    'identifier' in obj &&
    isString(obj.identifier) &&
    (isNil((obj as any)['mimeType']) ||
      ('mimeType' in obj &&
        typeof obj.mimeType === 'object' &&
        !isNil(obj.mimeType) &&
        'extension' in obj.mimeType &&
        isString(obj.mimeType.extension) &&
        'mime' in obj.mimeType &&
        isString(obj.mimeType.mime))) &&
    'size' in obj &&
    isNumeric(obj.size)
  );
}
