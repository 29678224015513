import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { isMockAuth } from '@camino-solutions/core/environment';
import { isString } from '@camino-solutions/utils/typeguard';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

export function fileInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  let reqClone = req;
  const oAuthService = inject(OAuthService);

  if (isMockAuth() === false) {
    const bearer = oAuthService.getAccessToken()?.toString()?.trim();

    if (isString(bearer) && bearer.length > 0 && new URL(req.url).pathname.startsWith('/file/')) {
      reqClone = reqClone.clone({
        headers: req.headers.append('Authorization', `Bearer ${bearer}`),
      });
    }
  }
  return next(reqClone);
}
