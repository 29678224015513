import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@camino-solutions/core/environment';
import { map, Observable } from 'rxjs';
import { AbstractFileService } from './abstract-file.service';
import type { UploadedFile } from './uploaded-file';

@Injectable()
export class FileService extends AbstractFileService {
  readonly #http = inject(HttpClient);
  readonly #baseUrl = `${environment.urls.common.file}/file/`;

  upload(fileName: string, mimeType: string, bytes: Blob, size: number): Observable<UploadedFile> {
    const formData = new FormData();
    formData.append('MimeType', mimeType);
    formData.append('Size', size.toString());
    formData.append('file', bytes, fileName);

    return this.#http.post<UploadedFile>(`${this.#baseUrl}upload`, formData, {
      headers: new HttpHeaders({
        Accept: 'application/json',
        ContentType: 'multipart/form-data',
      }),
    });
  }

  download(identifier: string): Observable<File> {
    return this.#http
      .get<File>(`${this.#baseUrl}download/${identifier}`, { responseType: 'blob' as 'json' })
      .pipe(map((blob: Blob) => new File([blob], identifier, { type: blob.type })));
  }

  delete(identifier: string): Observable<void> {
    return this.#http.delete<void>(`${this.#baseUrl}delete/${identifier}`, {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    });
  }
}
