import { Observable } from 'rxjs';
import type { UploadedFile } from './uploaded-file';

export abstract class AbstractFileService {
  abstract upload(
    fileName: string,
    mimeType: string,
    bytes: Blob,
    size: number,
  ): Observable<UploadedFile>;

  abstract delete(identifier: string): Observable<void>;

  abstract download(identifier: string): Observable<File>;
}
